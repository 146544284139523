import React, { useCallback, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Prompt } from 'react-router';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from '../../../../../../common/components/ApolloError/GraphQLError';
import { useHistory, useLocation } from 'react-router-dom';
import {
  generateSubmissionPortalPreviewRoute,
  generateSubmissionPreviewRoute,
  PORTAL_PREVIEW_FLAG,
  QANextStep,
} from '../../../Preview/PreviewRoute';
import styledUpdateSubmission from './styledUpdateSubmission';
import { ACTION_LINK_HEIGHT } from '../../../../Footer/ActionLink';
import { ReleasePathContext, ReleasePathState } from '../ReleasePath/ReleasePathProvider';
import { ALERT_TEXT } from './UpdateSubmission.types';
import SubmissionStatusHeader from './SubmissionStatusHeader';
import { SubmissionContext } from '../Context/SubmissionProvider';
import DetailsStep from './DetailsStep';
import BaseInfoStep from './BaseInfoStep';
import LegalStep from './LegalStep';
import FullEditStep from './FullEditStep';

const Root = styled('div')(styledUpdateSubmission);

export type UpdateSubmissionStep = 'baseInfo' | 'details' | 'fullEdit' | 'legal';

interface UpdateSubmissionProps {
  navToFeedback: () => void;
  onChangedStep: (newStep: UpdateSubmissionStep) => void;
}

const SUBMITTED_FOR_APPROVAL_STATUSES = ['NEW_SUBMISSION', 'SYNC_REQUIRED'];

const UpdateSubmission: React.FC<UpdateSubmissionProps> = ({ navToFeedback, onChangedStep }) => {
  const { submission, gameBuild, modified } = useContext(SubmissionContext);
  const { setReleasePathState } = useContext(ReleasePathContext);
  const location = useLocation();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState<UpdateSubmissionStep | undefined>();
  const [error, setError] = useState<ApolloError | undefined>();

  const getReleasePathState = useCallback((): ReleasePathState => {
    if (!submission || !activeStep) {
      return 'your_game';
    }
    if (SUBMITTED_FOR_APPROVAL_STATUSES.includes(submission.status)) {
      return 'review';
    }
    switch (activeStep) {
      case 'legal':
        return 'submit';
      case 'details':
        return 'details';
      case 'fullEdit':
      case 'baseInfo':
      default:
        return 'your_game';
    }
  }, [activeStep, submission]);

  const getInitialActiveStep = useCallback(
    (fromQA = false): UpdateSubmissionStep => {
      if (submission?.status !== 'DRAFT') {
        return 'fullEdit';
      }
      if (fromQA) {
        return 'details';
      }
      return 'baseInfo';
    },
    [submission?.status],
  );

  /**
   * In some scenarios this method was called with outdated gameBuildId, even if it was called after updating submission state in context.
   * So it requires for the new gameBuildId to be passed as an argument.
   */
  const goToQA = (nextStep: QANextStep, gameBuildId: string) => {
    if (!submission) {
      return;
    }

    if (PORTAL_PREVIEW_FLAG) {
      const url = generateSubmissionPortalPreviewRoute(submission.id, {
        QANextStep: nextStep,
        draft: submission.status === 'DRAFT',
        gameBuildId,
      });
      window.location.href = url;
      return;
    }

    const preview = generateSubmissionPreviewRoute(submission.id, {
      QANextStep: nextStep,
      draft: submission.status === 'DRAFT',
    });
    history.push({
      pathname: preview.pathname,
      search: preview.query,
    });
  };

  const goToLegal = () => {
    setActiveStep('legal');
    onChangedStep('legal');
  };

  const goToDetails = () => {
    setActiveStep('details');
    onChangedStep('details');
  };

  const onError = (error?: ApolloError) => {
    setError(error);
  };

  useEffect(() => {
    // Display alert when user tries to leave the page (quit/refresh) with unsaved changes
    // Complementary to the Prompt component, that only works when navigating within the app
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (modified) {
        event.preventDefault();
        event.returnValue = ALERT_TEXT;
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    setReleasePathState(getReleasePathState());
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [getReleasePathState, modified, setReleasePathState]);

  useEffect(() => {
    const paramsFromUrl = new URLSearchParams(location.search);
    const fromQA = !!submission && !!gameBuild && paramsFromUrl.get('fromQA') === 'true' && !!gameBuild.qaResult;
    const activeStep = getInitialActiveStep(fromQA);
    setActiveStep(activeStep);
    onChangedStep(activeStep);
  }, [getInitialActiveStep, location.search, onChangedStep, submission, gameBuild]);

  useEffect(() => {
    if (!submission) {
      return;
    }
    setReleasePathState(getReleasePathState());
  }, [activeStep, getReleasePathState, setReleasePathState, submission]);

  return (
    <Root sx={{ pb: `${ACTION_LINK_HEIGHT}px` }}>
      <SubmissionStatusHeader navToFeedback={navToFeedback} />
      <Prompt when={modified} message={ALERT_TEXT} />
      <Root>
        {activeStep === 'baseInfo' && <BaseInfoStep goToQA={goToQA} goToDetails={goToDetails} onError={onError} />}
        {activeStep === 'details' && <DetailsStep goToLegal={goToLegal} onError={onError} />}
        {activeStep === 'legal' && <LegalStep onError={onError} />}
        {activeStep === 'fullEdit' && <FullEditStep goToQA={goToQA} onError={onError} />}
      </Root>
      <GraphQLError error={error} />
    </Root>
  );
};

export default UpdateSubmission;
