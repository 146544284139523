interface RedirectTo {
  pathname: string;
  search?: string;
  state?: { isNewUser: boolean | undefined };
}

export function getRedirectTo(path: string, state?: { isNewUser: boolean | undefined }) {
  const [pathName, query] = path.split('?');

  const redirectTo: RedirectTo = {
    pathname: pathName,
  };
  if (query) {
    redirectTo.search = query;
  }
  if (state) {
    redirectTo.state = state;
  }
  return redirectTo;
}
