import { gql } from '@apollo/client';
import { BUILDS_FRAGMENT, GameBuild } from '../Admin/Submission/graphql/game-build-graphql';

export const PREVIEW_QUERY = gql`
  query GetPreviewData($id: String!) {
    me {
      id
      submission(id: $id) {
        id
        gameName
        gameBuilds {
          ...GameBuildFragment
        }
      }
    }
  }
  ${BUILDS_FRAGMENT}
`;

export interface PreviewQueryInput {
  id: string;
}

export interface Developer {
  id: string;
  gameName: string;
  width: number;
  height: number;
}

export interface PreviewSubmission {
  id: string;
  gameName: string;
  gameBuilds: GameBuild[];
}

export interface PreviewQuery {
  me: {
    submission: PreviewSubmission;
  };
}
