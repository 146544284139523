import { AVAILABLE_LOCALES } from '../ads/locales';
import { Locale } from '../ads/locales';
import { LanguageStatuses, SeoLangStatusesWithLocale } from '../components/App/Content/Admin/SeoStatuses/SeoStatuses.helper';

export interface TranslatedEntry {
  locale: LOCALE;
  text: string;
}

export const TranslatedEntryGqlFields = 'locale, text';

export type LOCALE = Locale;

export const LOCALES: readonly LOCALE[] = AVAILABLE_LOCALES;
export const DEFAULT_LOCALE: LOCALE = 'en_US';
export const EU_LOCALES: LOCALE[] = ['de_DE', 'fr_FR', 'es_ES', 'it_IT', 'nl_NL', 'sv_SE', 'nb_NO', 'fi_FI'];

export const LOCALE_LABEL: { [L in LOCALE]: string } = {
  en_US: 'English',
  nl_NL: 'Dutch',
  es_ES: 'Spanish',
  it_IT: 'Italian',
  id_ID: 'Indonesian',
  fr_FR: 'French',
  ru_RU: 'Russian',
  pt_BR: 'Portugese',
  pl_PL: 'Polish',
  ro_RO: 'Romanian',
  de_DE: 'German',
  nb_NO: 'Norwegian',
  sv_SE: 'Swedish',
  fi_FI: 'Finnish',
  uk_UA: 'Ukranian',
  el_GR: 'Greek',
  da_DK: 'Danish',
  cs_CZ: 'Czech',
  hu_HU: 'Hungarian',
  tr_TR: 'Turkish',
  ar_SA: 'Arabic',
  vi_VN: 'Vietnamese',
  th_TH: 'Thai',
  ko_KR: 'Korean',
};

export type TranslationMap = { [L in LOCALE]: string };
export type TranslationValues<T> = { [K in keyof T]: TranslationMap };

export function convertSeoLanguageStatuses(entries: SeoLangStatusesWithLocale[]): LanguageStatuses {
  let result: Partial<LanguageStatuses> = {};
  for (const langStatus of entries) {
    const { locale, ...rest } = langStatus;
    result[locale] = rest;
  }
  return result as LanguageStatuses;
}

export function convertTranslatedEntries(entries: TranslatedEntry[]): TranslationMap {
  const result: Partial<TranslationMap> = {};
  for (const { locale, text } of entries) {
    result[locale] = text;
  }
  return result as TranslationMap;
}

export function convertTranslationMap(map: TranslationMap): TranslatedEntry[] {
  return Object.entries(map).map(([key, value]) => ({
    locale: key as LOCALE,
    text: value as string,
  }));
}
