import { LOCALE } from '../i18n';

const configFilePrefix = process.env.REACT_APP_CONFIG || 'local';

export interface Config {
  graph: string;
  userPortal: string;
  firebase: FirebaseConfig;
  sentry: SentryConfig;
  aws: AWSConfig;
  ga4ID?: string;
  domains: DomainsConfig;
  kidSiteDomain: KidSiteDomainConfig;
}

export type DomainsConfig = {
  [locale in LOCALE]: {
    base: string;
    pathname: string;
  };
};

export interface KidSiteDomainConfig {
  base: string;
}

/**
 * Obtained from firebase > project > add firebase to your webapp
 */
export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
}

/**
 * Obtained from sentry > projects > ... > client keys
 */
export interface SentryConfig {
  dsn: string;
  disabled?: boolean;
}

export interface AWSConfig {
  imagesCdnPrefix: string;
  videoCdnPrefix: string;
  filesDomain: string;
}

export const config: Config = require(`./${configFilePrefix}.config.json`);

export const appName = 'crazygames-devportal';
export const appVersion = process.env.REACT_APP_VERSION || '0.0.0';

export default config;
