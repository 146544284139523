import config from '../../common/config';

interface CFImageOptions {
  width?: number;
  height?: number;
  fit?: 'contain' | 'scale-down' | 'cover' | 'crop' | 'pad';
  quality?: number;
  metadata?: 'keep' | 'copyright' | 'none';
  format?: 'auto';
}

const { imagesCdnPrefix } = config.aws;

export function image(src: string, options?: CFImageOptions): string {
  const baseOptions: CFImageOptions = {
    fit: 'contain',
    metadata: 'none',
    quality: 80,
  };
  const merged = Object.assign({}, baseOptions, options);
  const optionString = Object.entries(merged)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
  return `${imagesCdnPrefix}/${src}?${optionString}`;
}
