import { gql } from '@apollo/client';
import { mutate } from '../../../../../../common/graphql';
import { UploadedFileInput } from '../../../../../../common/graphql/upload-input';
import { SubmissionUnity56Encoding } from '../submission-graphql';
import { GAME_ORIENTATION } from '../../../../../../common/CzyForm/OrientationSelector';
import { GameCoverWithUploadedFileInput } from '../../../../../../common/graphql/games/game';
import { SubmissionAPSDetail } from '../../SubmitGame/submit-game-graphql';
import { GameLoaderType } from '../../../../../../common/domain/game';
import { BUILDS_FRAGMENT, GameBuild } from '../../../Admin/Submission/graphql/game-build-graphql';
import { FULLSCREEN_SETTING } from '../../../Admin/Game/GameframeConfig/GameframeConfig';

const UPDATE_SUBMISSION = gql`
  mutation updateSubmission($input: DeveloperSubmissionUpdateInput!) {
    developerSubmissionUpdate(input: $input) {
      id
      slug
      filesChanged
      requiresDevQaCheck
      coverChanged
      iframeLinkChanged
      gameBuilds {
        ...GameBuildFragment
      }
    }
  }
  ${BUILDS_FRAGMENT}
`;

export interface UpdateSubmissionMutation {
  developerSubmissionUpdate: UpdateSubmissionResult;
}

export interface UpdateSubmissionResult {
  id: string;
  slug: string;
  filesChanged?: boolean;
  coverChanged?: boolean;
  iframeLinkChanged?: boolean;
  requiresDevQaCheck?: boolean;
  gameBuilds: GameBuild[];
}

export interface DeveloperSubmissionUpdateInput {
  id: string;
  gameFiles: UploadedFileInput[];
  gameCovers?: GameCoverWithUploadedFileInput[];
  unity56Encoding?: SubmissionUnity56Encoding;

  gameName: string;
  categoryId?: string;
  tagIds?: string[];
  description?: string;
  controls?: string;

  iframeLink?: string;
  fullscreen: FULLSCREEN_SETTING;
  isIOSFriendly: boolean;
  isAndroidFriendly: boolean;
  orientation?: GAME_ORIENTATION;
  steamStoreLink?: string;
  appStoreLink?: string;
  playStoreLink?: string;
  playStoreDownloads?: number;
  appStoreDownloads?: number;
  steamDownloads?: number;
  hasIAP: boolean;
  isChromebookFriendly: boolean;

  gameLoaderType: GameLoaderType;
  apsDetail: SubmissionAPSDetail;
  isGameJam?: boolean;
}

export function updateSubmission(input: DeveloperSubmissionUpdateInput) {
  return mutate<DeveloperSubmissionUpdateInput, UpdateSubmissionMutation>(UPDATE_SUBMISSION, input);
}
