import { gql } from '@apollo/client';

export const QA_RESULT_FRAGMENT = gql`
  fragment QAResultFragment on QAResult {
    SDKImplemented
    SDKFeatures
    failedSDKFeatures
    unfulfilledMainRequirements
    adScenario
    SDKInfo {
      sdkType
      version
    }
    initialLoadSizeBytes
  }
`;
