import React, { useCallback, useContext, useState } from 'react';
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ActionLink from '../../../../Footer/ActionLink';
import { revenueShareFormToInput } from '../../SubmitGame/submission-helpers';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { SubmissionContext } from '../Context/SubmissionProvider';
import { DeveloperSubmissionSubmitForApprovalInput, submissionSubmitForApproval } from '../Mutations/update-submission-revenue-mutation';
import RevenueShareForm from '../RevenueShareForm/RevenueShareForm';
import Check from '../../../../../../common/icons/Check';
import { StyledBodyText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledButton } from '../../../../../../common/Styleguide/Common/Button';
import { useHistory } from 'react-router-dom';

interface BaseInfoStepProps {
  onError: (error?: ApolloError) => void;
}

// Dev can land on this step only if the submission is in DRAFT, files and details are filled out and QA is done
// Only possible next step is to submit for approval (or go back to draft)
const LegalStep: React.FC<BaseInfoStepProps> = ({ onError }) => {
  const { revenueShareForm, submission, revenueShareFormValid, setAttemptedToSave, setIsModified } = useContext(SubmissionContext);
  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFirstSubmission, setIsFirstSubmission] = useState(false);
  const history = useHistory();

  const submit = useCallback(async () => {
    if (!revenueShareFormValid) {
      setAttemptedToSave(true);
      return;
    }
    try {
      setIsSaving(true);
      const input = {
        id: submission!.id,
        revenueShareInputV3: revenueShareFormToInput(revenueShareForm!),
      } as DeveloperSubmissionSubmitForApprovalInput;
      const { data } = await submissionSubmitForApproval(input);
      setIsFirstSubmission(data?.developerSubmissionSubmitForApproval.isFirstSubmission ?? false);
      onError();
      setIsModified(false);
      toast.success('Draft successfully updated.');
      setConfirmationDialogOpened(true);
    } catch (err) {
      setIsSaving(false);
      toast.error('Error updating your game.');
      onError(err as ApolloError);
    }
  }, [onError, revenueShareForm, revenueShareFormValid, setAttemptedToSave, setIsModified, submission]);

  const renderConfirmationDialogComplete = useCallback(() => {
    const reloadWindow = () => {
      window.location.reload();
    };

    return (
      <Dialog open={confirmationDialogOpened} onClose={reloadWindow}>
        <DialogTitle id="alert-dialog-title" sx={{ pb: 0, mt: 1 }}>
          <Check color="success" style={{ width: 60, height: 60 }} />
          <br />
          Game submitted
        </DialogTitle>
        <DialogContent>
          <StyledBodyText id="alert-dialog-description" color="white60" sx={{ textAlign: 'center', px: 2 }}>
            Your game has been sent for review. We will notify you once the team has reviewed your game. You are still allowed to update
            your submission during that time.
          </StyledBodyText>
          {isFirstSubmission && (
            <Alert sx={{ marginBottom: 1 }} severity="warning">
              In order to receive payouts, you'll need to fill your payment details. Go{' '}
              <a href={'/profile/billing'} target="_blank" rel="noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>
                here
              </a>{' '}
              if you haven't done this yet.
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ py: 3 }}>
          <StyledButton onClick={reloadWindow} variant="contained" color="purple" height={50} sx={{ minWidth: 200 }}>
            Back to my game
          </StyledButton>
        </DialogActions>
      </Dialog>
    );
  }, [confirmationDialogOpened, isFirstSubmission]);

  return (
    <>
      <RevenueShareForm />
      <ActionLink
        primaryButton={{
          title: 'Submit for approval',
          callback: submit,
        }}
        primaryButtonDisabled={!revenueShareFormValid || isSaving}
        secondaryButton={{
          title: 'Back to draft',
          callback: () => {
            history.replace({ search: '' }); // remove possible fromQA query param
            window.location.reload();
          },
        }}
      />
      {renderConfirmationDialogComplete()}
    </>
  );
};

export default LegalStep;
