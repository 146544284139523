import React, { useCallback, useContext, useEffect, useState } from 'react';
import ActionLink from '../../../../Footer/ActionLink';
import { submissionToUpdateGraphQLInput } from '../../SubmitGame/submission-helpers';
import { toast } from 'react-toastify';
import { updateSubmission } from '../Mutations/update-submission-mutation';
import { ApolloError } from '@apollo/client';
import { SubmissionContext } from '../Context/SubmissionProvider';
import GameDetailsFormLoader from '../GameDetailsForm/GameDetailsFormLoader';
import { NON_RELEASED_STATUSES, REAPPROVAL_STATUSES, SYNC_STATUSES } from './UpdateSubmission.types';
import { QANextStep } from '../../../Preview/PreviewRoute';
import GameBaseFormFilesAndOptions from '../GameBaseForm/GameBaseFormFilesAndOptions';
import UpdateSubmissionIframeNotice from './UpdateSubmissionIframeNotice';
import GameBaseFormName from '../GameBaseForm/GameBaseFormName';
import { getLatestBuild } from '../../../../../../common/build-utils';

interface FullEditStepProps {
  goToQA(nextStep: QANextStep, gameBuildId: string): void;
  onError: (error: ApolloError) => void;
}

// Dev can land on this step only if submission is not in draft status (submitted already)
// Possible next steps are
// - Go to QA (not modified, QA pending)
// - Update and go to QA (modified that requires QA or QA pending)
// - Update (modified that doesn't require QA nor QA pending)
// - (Update and) Go to QA for re-approval if the submission is in a status that requires re-approval.
// Note: QA can be pending even without modification, if for example the user previously left the QA step without completing it
const FullEditStep: React.FC<FullEditStepProps> = ({ goToQA, onError }) => {
  const {
    baseForm,
    detailsForm,
    detailsFormProblems,
    baseFormProblems,
    submission,
    gameBuild,
    setAttemptedToSave,
    modified,
    revalidateByQA,
    setIsModified,
    isNonEditable,
    setBuildId,
  } = useContext(SubmissionContext);
  const [isSaving, setIsSaving] = useState(false);
  const [buttonInfo, setButtonInfo] = useState<{ title: string; callback: () => void } | null>(null);

  const submit = useCallback(
    async (nextStep?: (newGameBuildId: string) => void) => {
      if ((detailsFormProblems && detailsFormProblems.length > 0) || (baseFormProblems && baseFormProblems.length > 0)) {
        toast.error('Please check if all the mandatory fields marked with * are filled before continuing', { autoClose: 8000 });
        setAttemptedToSave(true);
        return;
      }

      setIsSaving(true);

      try {
        const updateInput = submissionToUpdateGraphQLInput(submission!.id, baseForm!, detailsForm);
        const result = await toast.promise(updateSubmission(updateInput), {
          pending: 'Updating submission...',
          success: 'Submission Updated!',
          error: 'Error updating submission',
        });
        const { gameBuilds } = result.data!.developerSubmissionUpdate;
        const latestBuild = getLatestBuild(gameBuilds);
        setBuildId(latestBuild.id, () => {
          setIsSaving(false);
          setIsModified(false, () => {
            if (nextStep) {
              // modified hook doesn't manage to run in parent on context update so delay the nextStep or you get the "Unsaved changes" warning
              setTimeout(() => {
                nextStep(latestBuild.id);
              }, 0);
            }
          });
        });
      } catch (err) {
        onError(err as ApolloError);
        setIsSaving(false);
      }
    },
    [baseFormProblems, baseForm, detailsForm, detailsFormProblems, onError, setAttemptedToSave, setIsModified, submission, setBuildId],
  );

  const isIframeNotifyEnabled = useCallback(() => {
    if (!submission) {
      return false; // This screen should not be visible if there is no submission
    }
    return (
      gameBuild?.loaderType === 'iframe' &&
      baseForm.gameLoaderType === 'iframe' &&
      !NON_RELEASED_STATUSES.includes(submission.status) &&
      !isNonEditable &&
      !modified
    );
  }, [baseForm.gameLoaderType, isNonEditable, modified, submission, gameBuild]);

  const renderIframeNotify = useCallback(() => {
    if (!isIframeNotifyEnabled()) {
      return null;
    }
    if (!submission) {
      return null; // This screen should not be visible if there is no submission
    }
    return <UpdateSubmissionIframeNotice submission={submission} />;
  }, [isIframeNotifyEnabled, submission]);

  const getButtonInfo = useCallback((): { title: string; callback: () => void } | null => {
    if (!submission) {
      return null; // This screen should not be visible if there is no submission
    }
    let reapprovalNextStep: QANextStep | undefined; // By default, no reapproval needed
    if (SYNC_STATUSES.includes(submission!.status)) {
      reapprovalNextStep = QANextStep.SYNC;
    }
    if (REAPPROVAL_STATUSES.includes(submission!.status)) {
      reapprovalNextStep = QANextStep.REAPPROVAL;
    }

    if (isIframeNotifyEnabled()) {
      // Iframe exception, using the "Notify Admin" button instead
      return null;
    }

    if (reapprovalNextStep) {
      const nextStep = reapprovalNextStep; // Store outside of callback
      if (modified) {
        return {
          title: 'Update and go to QA for re-approval',
          callback: () =>
            submit((gameBuildId) => {
              goToQA(nextStep, gameBuildId);
            }),
        };
      } else {
        return {
          title: 'Go to QA for re-approval',
          callback: () => goToQA(nextStep, gameBuild!.id),
        };
      }
    }

    if (!modified) {
      if (revalidateByQA) {
        return {
          title: 'Go to QA',
          callback: () => goToQA(QANextStep.UPDATE_COMPLETE, gameBuild!.id),
        };
      } else {
        return null;
      }
    }

    if (revalidateByQA) {
      return {
        title: 'Update and go to QA',
        callback: () =>
          submit((gameBuildId) => {
            goToQA(QANextStep.UPDATE_COMPLETE, gameBuildId);
          }),
      };
    }

    return {
      title: 'Update',
      callback: () => submit(),
    };
  }, [goToQA, isIframeNotifyEnabled, modified, revalidateByQA, submission, submit, gameBuild]);

  useEffect(() => {
    setButtonInfo(getButtonInfo());
  }, [getButtonInfo, goToQA, modified, revalidateByQA, submission, submit]);

  return (
    <>
      <GameBaseFormName />
      <GameDetailsFormLoader />
      <GameBaseFormFilesAndOptions />
      {renderIframeNotify()}
      {buttonInfo && (
        <ActionLink
          primaryButton={{
            title: buttonInfo.title,
            callback: buttonInfo.callback,
          }}
          primaryButtonDisabled={isSaving || baseForm.uploadInProgress}
        />
      )}
    </>
  );
};

export default FullEditStep;
