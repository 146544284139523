import { Box, Grid } from '@mui/material';
import { useContext } from 'react';
import QAEventListener, { GFEventCallback, GFEventType } from '../../QAEventListener';
import LogItem from './LogItem';
import { styled } from '@mui/material/styles';
import { StyledBodyText, StyledHeaderTextH2 } from '../../../../../../../common/Styleguide/Common/Text';
import React from 'react';
import QAContext from './QAContext';
import { v4 as uuidv4 } from 'uuid';
import { PreviewSubmission } from '../../../../Preview/preview-graphql';
import { getLatestBuild } from '../../../../../../../common/build-utils';

const StyledGrid = styled(Grid)(({ theme }) => {
  return {
    paddingLeft: 4,
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 7,
      height: 7,
    },
    '&::-webkit-scrollbar-button': {
      width: 0,
      height: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.light,
      border: '0px none #ffffff',
      borderRadius: 50,
    },
  };
});

const loggedEvents: GFEventType[] = [
  'gameFinishedLoading',
  'sdkInit',
  'happytime',
  'gameplayStart',
  'gameplayStop',
  'sdkGameLoadingStart',
  'sdkGameLoadingStop',
  'rewardedAd',
  'midgameAd',
  'inviteLink',
  'banner',
  'responsiveBanner',
  'adFinished',
  'gameLoaded',
  'analyticsTrackIAPOrder',

  // user module stuff
  'requestXsollaUserToken',
  'isUserAccountAvailable',
  'getSystemInfo',
  'showAuthPrompt',
  'showAccountLinkPrompt',
  'getUser',
  'requestUserToken',
  'addScore',
  'addAuthListener',
  'removeAuthListener',
];

interface Props {
  submission?: PreviewSubmission;
}

/**
 * Displays a log list with the events received from gameframe.
 */
const LogList: React.FC<Props> = ({ submission }) => {
  const { logs, pushLog } = useContext(QAContext);
  const hasIAP = getLatestBuild(submission?.gameBuilds || [])?.hasIap;

  const onGFEvent: GFEventCallback = React.useCallback(
    (event, _data) => {
      if (!loggedEvents.includes(event)) {
        return;
      }
      pushLog({ event, time: new Date(), key: uuidv4() });
    },
    [pushLog],
  );

  return (
    <>
      <QAEventListener onGFEvent={onGFEvent} />

      <StyledHeaderTextH2 sx={{ m: 0, mb: 2 }}>Log</StyledHeaderTextH2>
      <StyledGrid item container direction="column" wrap="nowrap" sx={{ p: 0 }}>
        {logs.map((log) => (
          <Box key={log.key} sx={{ mb: 1.25 }}>
            <LogItem logEntry={log} />
            {!hasIAP && log.event === 'analyticsTrackIAPOrder' && (
              <StyledBodyText variant="bodyLower" color="error" sx={{ mb: 1, marginBlock: 0 }}>
                Please tick the in-game purchases checkbox
              </StyledBodyText>
            )}
          </Box>
        ))}
      </StyledGrid>
    </>
  );
};

export default LogList;
