import React, { useCallback } from 'react';
import { Tabs, useTheme } from '@mui/material';
import Preview from '../../../../../common/components/Preview/Preview';
import { Submission } from './submission-graphql';
import UpdateSubmission, { UpdateSubmissionStep } from './UpdateSubmission/UpdateSubmission';
import DeleteSubmission from './ManageSubmission';
import Container from '../../../../../common/Styleguide/Container';
import SubmissionProvider from './Context/SubmissionProvider';
import Release from './ReleaseSubmission';
import DeveloperSubmissionStats from './DeveloperSubmissionStats/DeveloperSubmissionStats';
import { COLORS } from '../../../../../common/Styleguide/Common/colors';
import { StyledTab } from './developerSubmission.styles';
import UserFeedback from './UserFeedback/UserFeedback';
import ReleasePath from './ReleasePath/ReleasePath';
import Feedback from '../../../../../common/components/Feedback';
import SubmissionStatusBadge from './SubmissionStatusBadge';
import { prefixGraphUrl } from '../../../../../common/util';
import ContentHeader from '../../../../../common/Styleguide/ContentHeader';
import { GameBuild } from '../../Admin/Submission/graphql/game-build-graphql';
import { SubmissionStatus } from '../../../../../common/domain/submission';
import CommercialDetailsTab from './CommercialDetailsTab';

export interface SubmissionProps {
  submission: Submission;
  gameBuild: GameBuild;
}

type TAB_VALUES = 'UPDATE' | 'PREVIEW' | 'USER_FEEDBACK' | 'TEAM_FEEDBACK' | 'RELEASE' | 'MANAGE' | 'STATS' | 'COMMERCIAL';

const DeveloperSubmission: React.FC<SubmissionProps> = ({ submission, gameBuild }) => {
  const defaultTab: TAB_VALUES = submission.status === 'RELEASED' || submission.status === 'FINALIZED' ? 'STATS' : 'UPDATE';
  const [tab, setTab] = React.useState<TAB_VALUES>(defaultTab);
  const [headHidden, setHeadHidden] = React.useState(false);

  const { spacing } = useTheme();

  const gameCover16x9 = submission.gameCovers?.find((gameCover) => gameCover.size === '16x9');
  const coverImageUrl = gameCover16x9 && gameCover16x9.uploadedFile ? gameCover16x9.uploadedFile.temporaryUrl : null;
  const coverImgSrc = coverImageUrl && prefixGraphUrl(coverImageUrl);
  const shouldDisplayReleaseTab = submission.status === 'SYNC_REQUIRED' && submission.developer?.hasReleaseRights;
  const shouldDisplayTeamFeedbackTab = submission.feedback.length > 0 && submission.status !== 'DRAFT';
  //@TODO Enable UserFeedback below whenever it gets implemented
  const shouldDisplayUserFeedbackTab = false; // !['REJECTED', 'NEW_SUBMISSION', 'DRAFT', 'REQUIRES_APPROVAL', 'AWAITING_DEVELOPER_CHANGE'].includes(submission.status);
  const shouldDisplayStatisticsTab =
    submission.status === 'RELEASED' || submission.status === 'SYNC_REQUIRED' || submission.status === 'FINALIZED';
  const shouldDisplayCommercialInfoTabStatuses: SubmissionStatus[] = ['RELEASED', 'SYNC_REQUIRED', 'FINALIZED', 'PREPARING_RELEASE'];
  const shouldDisplayCommercialInfoTab = shouldDisplayCommercialInfoTabStatuses.includes(submission.status);
  const shouldDisplayDetailsTab = submission.status !== 'FINALIZED';
  const shouldDisplayPreviewTab = submission.status !== 'FINALIZED';

  const handleStepChange = useCallback((newStep: UpdateSubmissionStep) => {
    if (newStep === 'legal') {
      setHeadHidden(true);
    } else {
      setHeadHidden(false);
    }
  }, []);

  return (
    <SubmissionProvider initialSubmission={submission} initialGameBuild={gameBuild}>
      {submission.status !== 'REJECTED' && !submission.isGameJam && <ReleasePath />}
      {headHidden ? (
        <ContentHeader title="Submit Game" />
      ) : (
        <div
          style={{
            height: submission.status === 'DRAFT' ? 120 : 170,
            width: '100%',
            backgroundColor: COLORS.black[80],
            boxShadow: `0px 2px 0px ${COLORS.black[60]}`,
            position: 'relative',
            display: 'flex',
          }}
        >
          {coverImgSrc && (
            <div style={{ width: 125, height: 70, margin: 20 }}>
              <img src={coverImgSrc} alt={`${submission.gameName}_cover`} style={{ height: 70, borderRadius: 7 }} />
            </div>
          )}

          <div style={{ marginLeft: coverImgSrc ? 0 : 20 }}>
            <h1 style={{ fontSize: 28, marginBlock: 0, marginTop: spacing(2), marginBottom: spacing(0.5) }}>{submission.gameName}</h1>
            <SubmissionStatusBadge submission={submission} />
          </div>

          {submission.status !== 'DRAFT' && (
            <Tabs value={tab} onChange={(_e, newValue: TAB_VALUES) => setTab(newValue)} style={{ position: 'absolute', bottom: -2 }}>
              {shouldDisplayStatisticsTab && <StyledTab label="Statistics" value="STATS" />}
              {shouldDisplayUserFeedbackTab && <StyledTab label="User Feedback" value="USER_FEEDBACK" />}
              {shouldDisplayDetailsTab && <StyledTab label="Details" value="UPDATE" />}
              {shouldDisplayCommercialInfoTab && <StyledTab label="Commercial details" value="COMMERCIAL" />}
              {shouldDisplayPreviewTab && <StyledTab label="Preview" value="PREVIEW" />}
              {shouldDisplayTeamFeedbackTab && <StyledTab label="Team Feedback" value="TEAM_FEEDBACK" />}
              {shouldDisplayReleaseTab && <StyledTab label="Release" value="RELEASE" />}
              <StyledTab label="Manage" value="MANAGE" />
            </Tabs>
          )}
        </div>
      )}

      {shouldDisplayStatisticsTab && (
        <div role="tabpanel" hidden={tab !== 'STATS'}>
          <DeveloperSubmissionStats submission={submission} />
        </div>
      )}

      <div role="tabpanel" hidden={tab !== 'UPDATE'}>
        <UpdateSubmission navToFeedback={() => setTab('TEAM_FEEDBACK')} onChangedStep={handleStepChange} />
      </div>

      <div role="tabpanel" hidden={tab !== 'COMMERCIAL'}>
        <CommercialDetailsTab />
      </div>

      <div role="tabpanel" hidden={tab !== 'PREVIEW'}>
        <Container>
          <Preview />
        </Container>
      </div>

      {shouldDisplayUserFeedbackTab && (
        <div role="tabpanel" hidden={tab !== 'USER_FEEDBACK'}>
          <Container>
            <UserFeedback submission={submission} />
          </Container>
        </div>
      )}

      {shouldDisplayReleaseTab && (
        <div role="tabpanel" hidden={tab !== 'RELEASE'}>
          <Release submission={submission} />
        </div>
      )}

      {shouldDisplayTeamFeedbackTab && (
        <div role="tabpanel" hidden={tab !== 'TEAM_FEEDBACK'}>
          <Container>
            <div style={{ padding: spacing(3) }}>
              <Feedback feedback={submission.feedback} />
            </div>
          </Container>
        </div>
      )}

      <div role="tabpanel" hidden={tab !== 'MANAGE'} style={{ paddingTop: 16 }}>
        <Container>
          <DeleteSubmission submission={submission} />
        </Container>
      </div>
    </SubmissionProvider>
  );
};

export default DeveloperSubmission;
