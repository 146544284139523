const PREFIX = 'UpdateSubmission';

export const classes = {
  submit: `${PREFIX}-submit`,
  button: `${PREFIX}-button`,
  subtitle: `${PREFIX}-subtitle`,
  updateNotesContainer: `${PREFIX}-updateNotesContainer`,
  notifyButton: `${PREFIX}-notifyButton`,
  divider: `${PREFIX}-divider`,
};

const styledUpdateSubmission = ({ theme: { spacing } }: any) => ({
  [`& .${classes.submit}`]: {
    marginTop: spacing(),
    marginBottom: spacing(),
  },

  [`& .${classes.button}`]: {
    marginTop: spacing(),
    marginRight: spacing(),
    marginBottom: spacing(),
  },

  [`& .${classes.subtitle}`]: {
    marginTop: spacing(),
    marginBottom: spacing(),
  },

  [`& .${classes.updateNotesContainer}`]: {
    marginTop: spacing(5),
    marginBottom: spacing(),
  },

  [`& .${classes.notifyButton}`]: {
    width: spacing(20),
  },

  [`& .${classes.divider}`]: { marginTop: 60, marginBottom: spacing(2) },
});

export default styledUpdateSubmission;
