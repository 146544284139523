import React from 'react';
import { MainRequirement } from './Requirements/MainRequirements';
import { SDKFeatures, SDKYesNoFeatures } from './Requirements/SDKRequirements';
import { SDKInfo } from '../QAEventListener';
import { useHistory } from 'react-router-dom';
import ActionLink, { ActionLinkButton } from '../../../../Footer/ActionLink';
import { PreviewOptions } from '../../../Preview/PreviewRoute';
import SubmissionSubmitManager from './SubmissionSubmitManager';
import { PreviewSubmission } from '../../../Preview/preview-graphql';
import { RequirementState } from './Requirements/Requirement';
import { YesNoRequirementState } from './Requirements/YesNoRequirement';
import { getDetectedSDKFeatures, getUnfulfilledMainRequirements, getFailedSDKFeatures } from './Requirements/RequirementsUtils';

export interface QAResult {
  unfulfilledMainRequirements: MainRequirement[];
  SDKImplemented: boolean;
  SDKFeatures: (SDKFeatures | SDKYesNoFeatures)[];
  failedSDKFeatures: (SDKFeatures | SDKYesNoFeatures)[];
  SDKInfo?: SDKInfo;
  adScenario: string;
  initialLoadSizeBytes?: number;
}

export interface PortalQAToolResult {
  requirements: {
    sdkRequirements: Record<SDKFeatures, RequirementState>;
    sdkYesNoRequirements: Record<SDKYesNoFeatures, YesNoRequirementState>;
    loading: RequirementState;
    displaysCorrectly: YesNoRequirementState;
  };
  submissionId: string;
  gameBuildId: string;
  SDKInfo?: SDKInfo;
  initialLoadSizeBytes: number | null;
}

export function portalQaDataToQaResult(qaData: PortalQAToolResult, adScenario: string): QAResult {
  const { sdkRequirements, sdkYesNoRequirements, loading, displaysCorrectly } = qaData!.requirements;

  const detectedSDKFeatures = getDetectedSDKFeatures(sdkRequirements, sdkYesNoRequirements);
  const unfulfilledMainRequirements = getUnfulfilledMainRequirements(loading, displaysCorrectly);
  const failedSDKFeatures = getFailedSDKFeatures(sdkRequirements, sdkYesNoRequirements);

  const qaResult: QAResult = {
    unfulfilledMainRequirements: unfulfilledMainRequirements.map((r) => r.key),
    SDKImplemented: detectedSDKFeatures.length > 0,
    SDKFeatures: detectedSDKFeatures.map((f) => f.key),
    failedSDKFeatures: failedSDKFeatures.map((f) => f.key),
    SDKInfo: qaData.SDKInfo
      ? {
          // pick the correct fields to avoid graphQL errors in case new init fields appear in the SDKInfo
          sdkType: qaData!.SDKInfo.sdkType,
          version: qaData!.SDKInfo.version,
        }
      : undefined,
    adScenario,
    initialLoadSizeBytes: qaData.initialLoadSizeBytes ?? undefined,
  };

  return qaResult;
}

const StandAloneSubmitManager: React.FC = () => {
  const history = useHistory();
  const getPrimaryButton = (): ActionLinkButton => {
    return {
      title: 'Quit QA tool',
      callback: () => {
        history.push('/qatool');
      },
    };
  };

  return <ActionLink primaryButton={getPrimaryButton()} secondaryButton={undefined} />;
};

interface SubmitManagerProps {
  submission?: PreviewSubmission;
  previewOptions: PreviewOptions;
  onMobile: boolean;
}

const SubmitManager: React.FC<SubmitManagerProps> = ({ submission, previewOptions, onMobile }) => {
  if (onMobile) {
    return null;
  }
  if (submission) {
    return <SubmissionSubmitManager submission={submission} previewOptions={previewOptions} />;
  } else {
    return <StandAloneSubmitManager />;
  }
};

export default SubmitManager;
