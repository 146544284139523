import React from 'react';
import { StyledContainer } from '../../../../../../common/Styleguide/Common/Container';
import { StyledBodyText, StyledHeaderText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledInput } from '../../../../../../common/Styleguide/Common/Input';
import { classes } from './styledUpdateSubmission';
import { Submission } from '../submission-graphql';
import { notifyResubmitIframeSubmission } from '../Mutations/update-submission-status-mutation';
import { StyledLoadingButton } from '../../../../../../common/Styleguide/Common/Button';

interface UpdateSubmissionIframeNoticeProps {
  submission?: Submission;
}

type Status = 'none' | 'loading' | 'success' | 'error';

const UpdateSubmissionIframeNotice: React.FC<UpdateSubmissionIframeNoticeProps> = ({ submission }) => {
  const [notice, setNotice] = React.useState<string>('');
  const [status, setStatus] = React.useState<Status>('none');
  const notifyForIframeContentsUpdate = async () => {
    try {
      const input = {
        id: submission!.id,
        updateNoteText: notice,
      };
      setStatus('loading');
      await notifyResubmitIframeSubmission(input);
      setStatus('success');
    } catch (error) {
      setStatus('error');
      console.error(error);
    }
  };

  const isDisabled = () => {
    return status === 'success' || notice.length === 0;
  };

  return (
    <StyledContainer
      sx={{
        width: 900,
        m: 2,
        mt: 3,
        p: 2,
        px: 3,
      }}
    >
      <StyledHeaderText
        variant="h2"
        sx={{
          paddingY: 1,
          margin: 0,
        }}
      >
        IFrame update notes for Admin*
      </StyledHeaderText>
      <StyledBodyText sx={{ mt: 0, mb: 1 }} color="black10">
        Write a summary of the update and mark the important ones. Include changes to our SDK ads (if any)
      </StyledBodyText>
      <StyledInput
        multiline={true}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          const value = evt.target.value;
          setNotice(value);
          setStatus('none');
        }}
      />
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <StyledLoadingButton
          variant="contained"
          color="purple"
          disabled={isDisabled()}
          className={classes.notifyButton}
          onClick={notifyForIframeContentsUpdate}
          loading={status === 'loading'}
        >
          Notify Admin
        </StyledLoadingButton>
        {status === 'error' && (
          <StyledBodyText sx={{ marginBlock: 0 }} variant="body" color="error">
            Something went wrong, please try again later
          </StyledBodyText>
        )}
        {status === 'success' && (
          <StyledBodyText sx={{ marginBlock: 0 }} variant="body" color="success">
            Update notes submitted
          </StyledBodyText>
        )}
      </div>
      <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="black10">
        Notify the admin to check the updates made on your game's iframe URL
      </StyledBodyText>
    </StyledContainer>
  );
};

export default UpdateSubmissionIframeNotice;
