import config from '../../../../../common/config';
import { DEFAULT_LOCALE } from '../../../../../common/i18n';
import { styled } from '@mui/system';
import { Link } from '@mui/material';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

export const GAMEJAM_LINK = 'https://jam.crazygames.com';
export const GAMEJAM_LINK_DISPLAY = 'jam.crazygames.com';

const TIMEZONE = 'CET';
const GAMEJAM_START = fromZonedTime('2024-11-01 00:00:00', TIMEZONE);
const GAMEJAM_END = fromZonedTime('2024-11-08 23:59:59', TIMEZONE);

export function getGameJamLink(submissionId: string) {
  const domain = `${config.domains[DEFAULT_LOCALE].base}${config.domains[DEFAULT_LOCALE].pathname}`;
  return `${domain}/preview/${submissionId}`;
}

export const GameJamLink = styled(Link)(() => ({
  color: '#ff87dc',
  textDecoration: 'underline',
}));

export function isGameJamTime() {
  const paramsFromUrl = new URLSearchParams(window.location.search);
  const now = new Date();
  return (
    (isAfter(now, GAMEJAM_START) && isBefore(now, GAMEJAM_END)) || isEqual(now, GAMEJAM_END) || paramsFromUrl.get('goatjam') === 'true'
  );
}
