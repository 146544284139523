import React from 'react';
import Header from '../../Header/Header';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import withSession, { WithSession } from '../../../../common/Session/withSession';
import { image } from '../../../helpers/image';
import { LiberatedGame, LiberatedGameImg, Root, Section2List, SectionHorList } from './landingPage.styles';
import { StyledButton } from '../../../../common/Styleguide/Common/Button';
import Upload from '../../../../common/icons/Upload';
import Users from '../../../../common/icons/Users';
import Comments from '../../../../common/icons/Comments';
import Chart from '../../../../common/icons/Chart';
import { COLORS } from '../../../../common/Styleguide/Common/colors';
import Ads from '../../../../common/icons/Ads';
import Help from '../../../../common/icons/Help';
import Shooting from '../../../../common/icons/Shooting';
import SDK from '../../../../common/icons/SDK';
import Rise from '../../../../common/icons/Rise';
import TestimonialsGrid from './TestimonialsGrid';
import Earth from '../../../../common/icons/Earth';
import CrazyGamesLogoBW from '../../../../common/components/CrazyGamesLogo/CrazyGamesLogoBW';
import TikTok from '../../../../common/icons/TikTok';
import { LinkedIn } from '@mui/icons-material';
import Twitter from '../../../../common/icons/Twitter';
import { Box } from '@mui/material';
import QuoteSection from './Quote/QuoteSection';
import { DOCS_URL, FAQ_URL } from '../../SideMenu/Menu';
import { GameJamBanner, shouldShowGameJamBanner } from '../GameJamBanner/GameJamBanner';
import SessionContext from '../../../../common/Session/SessionContext';

type LandingPageProps = WithSession & RouteComponentProps<{}>;

const LandingPage: React.FC<LandingPageProps> = () => {
  const session = React.useContext(SessionContext);
  const shouldShowBanner = shouldShowGameJamBanner() && !session.isLoggedIn();

  return (
    <Root>
      <Header key="appbar" type="LANDING_PAGE" />
      {shouldShowBanner && (
        <div style={{ marginTop: 64 }}>
          <GameJamBanner />
        </div>
      )}
      <Box sx={{ mt: shouldShowBanner ? 8 : 15, mb: { xs: 0, md: 6 } }} component="section" className="blackSection">
        <Box
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: 'auto' }}
          sx={{ width: { xs: '90%', md: '85vw' }, maxWidth: { md: 1500 }, flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Box
            style={{ position: 'relative' }}
            sx={{
              width: { xs: '100%', md: '47%' },
              textAlign: { xs: 'center', md: 'left' },
              display: { xs: 'flex', md: 'block' },
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
            }}
          >
            <h1>Publish your game to millions on the web</h1>
            <h4 style={{ width: '92%', marginTop: 30 }}>
              CrazyGames is empowering developers to reach a new audience through{' '}
              <a href={'https://www.crazygames.com'} target="_blank" rel="nofollow noreferrer">
                browser games
              </a>
              . Go beyond native platforms, reach millions of players worldwide, and generate revenue from your games.
            </h4>
            <Link to="/register">
              <StyledButton variant="contained" style={{ marginTop: 30 }} height={50}>
                Submit my game
              </StyledButton>
            </Link>
            <Box component="a" href={DOCS_URL} target="_blank" rel="nofollow noreferrer" sx={{ mt: 2.5, display: { md: 'none' } }}>
              <StyledButton variant="outlined" height={50}>
                Documentation
              </StyledButton>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: '100%', md: '33vw' },
              height: { xs: '75vw', md: '28vw' },
              position: 'relative',
              mt: { xs: 3.5 },
            }}
          >
            <img src={image('devportal/laptop.png')} alt="Developer Portal" style={{ zIndex: 1, position: 'absolute', width: '100%' }} />
            <Box
              sx={{
                background: 'radial-gradient(50% 50% at 50% 50%, #7000FF 0%, rgba(155, 54, 255, 0) 100%)',
                opacity: 0.4,
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 0,
                left: { md: -44 },
                top: { md: 27 },
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box
        component="section"
        sx={{ p: { xs: 3, md: 8 }, background: 'linear-gradient(79.43deg, #651B9F 11.84%, #3E2899 92%)' }}
        className="purpleSection"
      >
        <Box
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}
          sx={{
            flexDirection: { xs: 'column-reverse', md: 'row' },
            '& h2': {
              textAlign: { xs: 'center', md: 'left' },
            },
          }}
        >
          <div style={{ position: 'relative' }}>
            <Box
              component="img"
              src={image('devportal/mobile.png')}
              alt="Developer Portal - Everything you need"
              sx={{ mr: { md: 6 }, maxWidth: '56vw' }}
            />
          </div>
          <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 500, alignItems: { xs: 'center', md: 'flex-start' } }}>
            <h2 style={{ marginBottom: 20, maxWidth: '91%' }}>Everything you need to go live and start earning</h2>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Section2List>
                <div>
                  <Upload />
                </div>
                <div>
                  <h3>Upload your game in minutes</h3>
                  <p>Your game files and a cover image are all you need to submit your game.</p>
                </div>
              </Section2List>
              <Section2List>
                <div>
                  <Comments />
                </div>
                <div>
                  <h3>Have your voice heard</h3>
                  <p>You'll get developer support that's second-to-none in the browser games industry.</p>
                </div>
              </Section2List>
              <Section2List>
                <div>
                  <Chart />
                </div>
                <div>
                  <h3>Gain valuable insights</h3>
                  <p>Access a range of data insights and get user feedback to help you optimize your game and increase earnings.</p>
                </div>
              </Section2List>
            </div>
          </Box>
        </Box>
      </Box>

      <Box component="section" sx={{ p: { xs: 2.5, md: 0 }, pt: { xs: 6, md: 17 } }} className="blackSection">
        <Box
          sx={{
            display: { sm: 'block', md: 'flex' },
            gap: 4,
            alignItems: 'center',
            px: { md: 20, sm: 2 },
            pb: 4,
            maxWidth: 1500,
            margin: 'auto',
          }}
        >
          <Box sx={{ textAlign: { sm: 'center', md: 'left' }, flex: 1 }}>
            <h2>Meet your global audience</h2>
            <br />
            <h4>
              CrazyGames unites players from around the world. With <strong>23 languages</strong> and a global base of{' '}
              <strong>35-million users</strong>, it's the go-to platform for web gamers worldwide.
            </h4>
            <Box
              sx={{
                display: { xs: 'none', md: 'block' },
              }}
            >
              <br />
            </Box>
            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
                flex: 1,
                py: 2,
                textAlign: 'center',
              }}
            >
              <img src={image('devportal/map2.png')} alt="Meet your global audience" style={{ width: 'min(1000px, 90%)' }} />
            </Box>
            <h4>
              That said, CrazyGames is leading the way in English-speaking countries like the US, UK, and Australia. Ads in these countries
              provide the highest earnings for your games.
            </h4>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'block' },
              textAlign: 'center',
            }}
          >
            <img src={image('devportal/map2.png')} alt="Meet your global audience" style={{ width: 450 }} />
          </Box>
        </Box>
        <QuoteSection
          quote="Friendly staff, easy to use developer portal, millions of monthly players and good earnings."
          author="Konstantin"
          title="CEO at MadPuffers"
          authorImage="Konstantin.png"
        />
      </Box>

      <Box
        component="section"
        sx={{
          padding: { xs: 2.5, md: 0 },
          background: 'linear-gradient(90deg, #651B9F 23.31%, #3E2899 96.09%)',
          textAlign: 'center',
          pb: { xs: '45.5vw', md: 51 },
          pt: { xs: 6, md: 17 },
        }}
        className="purpleSection"
      >
        <Box sx={{ maxWidth: 820, margin: 'auto', mb: { md: 8 } }}>
          <h2>Monetize your games the right way</h2>
        </Box>
        <Box sx={{ maxWidth: 1000, margin: 'auto', display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
          <SectionHorList>
            <Ads style={{ color: COLORS.brand[30] }} />
            <h3>High-yielding ads that are user friendly</h3>
            <p>Get the most out of your ads without eroding the user experience, ensuring players keep coming back to play your games.</p>
          </SectionHorList>
          <SectionHorList sx={{ mt: { xs: 3, md: 0 } }}>
            <Help style={{ color: COLORS.brand[30] }} />
            <h3>Tailored support to maximize earnings</h3>
            <p>Our dedicated ads team can help you optimize ad placements and earn more revenue while keeping gameplay at the forefront.</p>
          </SectionHorList>
          <SectionHorList sx={{ mt: { xs: 3, md: 0 } }}>
            <Shooting style={{ color: COLORS.brand[30] }} />
            <h3>Relevant targeting for your audience</h3>
            <p>We work with top ad agencies and brands to ensure highly targeted campaigns that deliver excellent game monetization.</p>
          </SectionHorList>
        </Box>
        <Box
          component="img"
          src={image('devportal/laptop-front.png')}
          alt="Monetize your games the right way"
          sx={{ width: { xs: '100%', md: 900 }, position: 'absolute', left: '50%', transform: 'translate(-50%)' }}
        />
      </Box>

      <Box
        component="section"
        sx={{
          position: 'relative',
          px: { xs: 4, md: 0 },
          pt: { xs: 6, md: 12 },
          textAlign: 'center',
        }}
        className="blackSection"
      >
        <Box sx={{ maxWidth: 820, margin: 'auto', mb: { md: 6 } }}>
          <h2>Browser gaming reimagined</h2>
          <h4 style={{ marginTop: 30 }}>The CrazyGames SDK simplifies gaming on the web.</h4>
        </Box>

        <Box
          sx={{
            maxWidth: 1000,
            margin: 'auto',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            mb: 6,
          }}
        >
          <SectionHorList>
            <Users style={{ color: COLORS.brand[100] }} />
            <h3>Community building tools</h3>
            <p>
              The CrazyGames SDK comes with multiplayer invite links built-in. Users can share the link with their friends, and just like
              that - they're in your game.
            </p>
          </SectionHorList>
          <SectionHorList>
            <SDK style={{ color: COLORS.brand[100] }} />
            <h3>Simple one-time integration</h3>
            <p>
              Our SDK is easy to integrate, increases your revenue through user-friendly ads, and comes with documentation that's simple to
              follow. You only have to do it once.
            </p>
            <a href={DOCS_URL} target="_blank" rel="nofollow noreferrer" style={{ marginTop: 20 }}>
              Read More ➞
            </a>
          </SectionHorList>
          <SectionHorList>
            <Rise style={{ color: COLORS.brand[100] }} />
            <h3>Performance optimization</h3>
            <p>
              The integrated WebGL optimization package reduces the build size of your games, improving performance without additional work
              on your end.
            </p>
            <a
              href={`${DOCS_URL}/sdk/unity/resources/optimizer-package/`}
              target="_blank"
              rel="nofollow noreferrer"
              style={{ marginTop: 20 }}
            >
              WebGL optimizer ➞
            </a>
          </SectionHorList>
        </Box>
        <QuoteSection
          quote="From clear guidelines, solid QA feedback, simple API integration and great communication whether it's with the submissions team or Discord, my experience so far has been a refreshing change from working with other distribution partners over the years."
          author="Jim"
          title="Happylander"
          authorImage="Happylander.png"
        />
      </Box>

      <Box
        component="section"
        sx={{
          background: 'linear-gradient(90deg, #651B9F 23.31%, #3E2899 96.09%)',
          pt: { xs: 5, md: 12 },
        }}
        className="purpleSection"
      >
        <Box
          sx={{
            display: 'flex',
            width: '85vw',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            mb: 6,
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', md: '41%' },
              textAlign: { xs: 'center', md: 'left' },
              pr: { md: 6 },
              position: 'relative',
              display: { xs: 'flex', md: 'block' },
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h2>Liberate your games from native platforms</h2>
            <h4 style={{ width: '92%', marginTop: 30 }}>
              It's easier than ever to get your games on the web and start earning revenue. The web is a uniquely unconstrained medium with
              the opportunity to reach more players across all devices.
            </h4>
            <br />
            <h4>It could take less than a day for you to port your native mobile app.</h4>
            <Link to="/register">
              <StyledButton variant="contained" color="white" sx={{ mt: 4, mb: { xs: 4, md: 0 } }} height={50}>
                Liberate my game
              </StyledButton>
            </Link>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'block' }, flexDirection: 'column', position: 'relative' }}>
            <LiberatedGame>
              <LiberatedGameImg src={image('hide-n-seek/20220216140400/hide-n-seek-cover', { width: 268 })} alt="Hide n Seek Cover" />
              <div className="liberatedContainer">
                <div className="liberatedTitle">YSO Corp</div>
                <div>4M+ gameplays</div>
              </div>
            </LiberatedGame>
            <LiberatedGame gamePosition={1}>
              <LiberatedGameImg src={image('market-boss/20220523115722/market-boss-cover', { width: 268 })} alt="Market Boss Cover" />
              <div className="liberatedContainer">
                <div className="liberatedTitle">Izyplay</div>
                <div>4M+ gameplays</div>
              </div>
            </LiberatedGame>
            <LiberatedGame gamePosition={2}>
              <LiberatedGameImg src={image('helix-jump/20220519091317/helix-jump-cover', { width: 268 })} alt="Helix Jump Cover" />
              <div className="liberatedContainer">
                <div className="liberatedTitle">Voodoo</div>
                <div>10M+ gameplays</div>
              </div>
            </LiberatedGame>
          </Box>
        </Box>
        <QuoteSection
          quote="It's weird that there aren't many other big players in the web market. Market Boss made 3x more revenue with CrazyGames than it did
        on mobile."
          author="Everton"
          title="Izyplay"
          authorImage="Everton.png"
        />
      </Box>

      <Box
        component="section"
        sx={{
          pt: { xs: 5, md: 14 },
          pb: { xs: 8, md: 12 },
          px: { xs: 4, md: 0 },
          textAlign: 'center',
        }}
        className="blackSection"
      >
        <div style={{ maxWidth: 820, margin: 'auto', marginBottom: 50 }}>
          <h2>The dev friendly home for your browser games</h2>
          <h4 style={{ marginTop: 30 }}>
            Publish with CrazyGames and you'll be part of a supportive ecosystem and community. Don't just take our word for it...
          </h4>
        </div>
        <TestimonialsGrid />
      </Box>

      <Box
        component="section"
        sx={{
          background: 'linear-gradient(-90deg, #651B9F 23.31%, #3E2899 96.09%)',
          py: { xs: 5, md: 12 },
        }}
        className="purpleSection"
      >
        <Box
          sx={{
            display: 'flex',
            width: { md: '85vw' },
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            flexDirection: { xs: 'column-reverse', md: 'row' },
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', md: '43%' },
              p: { xs: 3, md: 'unset' },
              pr: { md: 9 },
              position: 'relative',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <h2>Staying true to the ethos of browser games</h2>
            <h4 style={{ width: '92%', marginTop: 30, fontSize: 20, marginBottom: 20 }}>
              The web browser will always be the coolest place on the block to play games. It's an open medium that allows developers to
              gain visibility and earn their fair share. We want to keep it that way.
            </h4>
            <h4 style={{ fontSize: 20, marginBottom: 20 }}>
              The success of your game depends on how much engagement it gets from our users. This includes the number of plays, the
              duration of gameplay sessions, user reviews, and retention. By creating a captivating and enjoyable experience, your game can
              attract a loyal player base and achieve lasting popularity.
            </h4>
            <h4 style={{ fontSize: 20 }}>
              Many of our most successful games come from small and solo game developers. That's what makes the web magic.
            </h4>
          </Box>

          <Box
            component="img"
            src={image('devportal/screenshots.png')}
            alt="CrazyGames screenshots"
            sx={{ maxWidth: { xs: 300, md: 600 }, width: { xs: '100%', md: '30vw' }, mb: { md: -10 }, mr: { xs: -2, md: 'unset' } }}
          />
        </Box>
      </Box>

      <Box
        component="section"
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: { xs: 5, md: 12 },
          px: { xs: 3, md: 'unset' },
        }}
        className="blackSection"
      >
        <Box sx={{ maxWidth: 820, margin: 'auto', mb: { md: 6 } }}>
          <h2>Why should you publish with CrazyGames?</h2>
        </Box>

        <Box sx={{ maxWidth: 1135, margin: 'auto', display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
          <SectionHorList>
            <Earth />
            <h3>Broad audience</h3>
            <p>Your audience is primarily in top-tier countries that provide the best earnings for your game.</p>
          </SectionHorList>
          <SectionHorList>
            <Help />
            <h3>Quality support</h3>
            <p>We provide a supportive ecosystem and community to help you succeed.</p>
          </SectionHorList>

          <SectionHorList>
            <Rise />
            <h3>Revenue stream</h3>
            <p>Our SDK is easy to integrate and you’ll earn more money by using it.</p>
          </SectionHorList>
        </Box>
        <a href="https://about.crazygames.com/" target="_blank" rel="nofollow noreferrer" style={{ marginTop: 48 }}>
          <StyledButton variant="contained" color="purple" height={50}>
            Learn more about us
          </StyledButton>
        </a>
      </Box>

      <Box
        component="section"
        sx={{
          background: COLORS.brand[110],
          height: { md: 544 },
          width: '100%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: { xs: 3, md: 'unset' },
          py: { xs: 8, md: 'unset' },
        }}
        className="purpleSection"
      >
        <div style={{ maxWidth: 520, zIndex: 3, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2>Forge a new audience with CrazyGames</h2>
          <Link to="/register">
            <StyledButton variant="contained" color="white" height={50} style={{ marginTop: 32, marginBottom: 32 }}>
              Share your game
            </StyledButton>
          </Link>
          <h4 style={{ fontWeight: 700, color: COLORS.white[80], marginBottom: 8 }}>Need more information?</h4>
          <p>
            Read our{' '}
            <a href={DOCS_URL} target="_blank" rel="nofollow noreferrer" className="underlinedAnchor">
              documentation
            </a>{' '}
            and{' '}
            <a href={FAQ_URL} target="_blank" rel="nofollow noreferrer" className="underlinedAnchor">
              FAQ
            </a>
            , or{' '}
            <a href="https://www.crazygames.com/contact" target="_blank" rel="nofollow noreferrer" className="underlinedAnchor">
              ask us a question
            </a>
            !
          </p>
        </div>
        <div
          style={{
            background: 'linear-gradient(180deg, #5E3BE6 0%, rgba(94, 59, 230, 0.85) 100%)',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            zIndex: 2,
            height: '100%',
          }}
        />
        <img
          src={image('devportal/bottomBack.png')}
          alt="Bottom background"
          style={{ position: 'absolute', bottom: 0, left: 0, zIndex: 1, width: '100%' }}
        />
      </Box>

      <section style={{ background: COLORS.black[100], width: '100%' }}>
        <Box
          sx={{
            maxWidth: 1100,
            height: { md: 110 },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            p: { xs: 8, md: 'unset' },
            px: { md: 8 },
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 'auto',
            gap: { xs: 3, md: 'unset' },
          }}
        >
          <a href="https://www.crazygames.com" target="_blank" rel="nofollow noreferrer">
            <CrazyGamesLogoBW />
          </a>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <a href="https://www.tiktok.com/@crazygames" target="_blank" rel="nofollow noreferrer">
              <StyledButton variant="outlined" height={35} style={{ padding: 8, marginRight: 10 }}>
                <TikTok />
              </StyledButton>
            </a>
            <a href="https://www.linkedin.com/company/crazygames/" target="_blank" rel="nofollow noreferrer">
              <StyledButton variant="outlined" height={35} style={{ padding: 8, marginRight: 10 }}>
                <LinkedIn />
              </StyledButton>
            </a>
            <a href="https://twitter.com/CrazyGamesCom" target="_blank" rel="nofollow noreferrer">
              <StyledButton variant="outlined" height={35} style={{ padding: 8 }}>
                <Twitter />
              </StyledButton>
            </a>
          </div>
        </Box>
      </section>
    </Root>
  );
};

export default withSession(withRouter(LandingPage));
