import React from 'react';
import DeveloperSubmission from './DeveloperSubmission';
import { GetMySubmissionQuery, GET_MY_SUBMISSION_QUERY } from './submission-graphql';
import GraphQLLoader from '../../../../../common/graphql/graphql-loader';
import { useQuery } from '@apollo/client';
import { getLatestBuild } from '../../../../../common/build-utils';

export interface SubmissionLoaderInput {
  submissionId: string;
}

const SubmissionLoader: React.FC<SubmissionLoaderInput> = ({ submissionId }) => {
  const { loading, error, data } = useQuery<GetMySubmissionQuery>(GET_MY_SUBMISSION_QUERY, {
    variables: { id: submissionId },
    fetchPolicy: 'network-only',
  });

  return (
    <GraphQLLoader loading={loading} error={error}>
      {!loading && !error && (
        <DeveloperSubmission submission={data!.me.submission} gameBuild={getLatestBuild(data!.me.submission.gameBuilds)} />
      )}
    </GraphQLLoader>
  );
};

export default SubmissionLoader;
