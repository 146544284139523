// NOTE: THIS FILE GETS COPY-PASTED INTO MULTIPLE OTHER REPOSITORIES
// THE ORIGINAL SOURCE FILE IS CONTAINED IN THE crazygames-ads REPOSITORY
// CHANGES SHOULD BE MADE TO THAT FILE
// AND WHEN YOU MAKE CHANGES, MAKE SURE TO COPY PASTE THEM TO ALL THE REPOSITORIES
// YOU SHOULD USE
// npm run-script copy-typings
// FOR THIS
//
// THESE TYPINGS ARE CONTAINED IN A SEPARATE FILE AS THIS FILE ISN'T COPIED TO ALL REPOSITORIES
// SOME REPOSITORIES DON'T HAVE ACCESS TO THE Window INTERFACE AS THOSE ARE BACKEND REPOS
export const AVAILABLE_LOCALES = [
  'en_US',
  'es_ES',
  'id_ID',
  'it_IT',
  'nl_NL',
  'fr_FR',
  'pt_BR',
  'ru_RU',
  'pl_PL',
  'ro_RO',
  'de_DE',
  'nb_NO',
  'sv_SE',
  'fi_FI',
  'uk_UA',
  'el_GR',
  'da_DK',
  'cs_CZ',
  'hu_HU',
  'tr_TR',
  'ar_SA',
  'vi_VN',
  'th_TH',
  'ko_KR',
] as const;
export type Locale = typeof AVAILABLE_LOCALES[number];
