import { gql } from '@apollo/client';

export const GAME_FILE_FRAGMENT = gql`
  fragment GameFileragment on GameFile {
    uploadFileId
    fileName
    publicUrl
    size # bytes
  }
`;
